import React from "react";
import { SAMPLE_IMAGES } from "../data";

function SampleHome() {
  return (
    <>
      <h1 className="text-[24px] font-bold text-center mt-8">Samples</h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 container mx-auto w-[84%] mt-10">
        {SAMPLE_IMAGES.map((x) => (
          <>
            <img src={x.img} alt="samples images" />
          </>
        ))}       
      </div>
    
    </>
  );
}

export default SampleHome;
